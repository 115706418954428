<template>
  <div>
    <Molded>
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="6" xl="6">
          <InputText
            title="Nome"
            field="name"
            :formName="formName"
            :required="true"
            :maxLength="50"
            v-model="user.name"
          >
          </InputText>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="6" xl="6">
          <InputText
            title="E-mail"
            field="email"
            :formName="formName"
            :required="true"
            :maxLength="50"
            v-model="user.email"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="6" xl="6">
          <InputText
            title="Login"
            field="userName"
            :formName="formName"
            :required="true"
            :maxLength="50"
            v-model="user.userName"
          >
          </InputText
        ></b-col>
        <b-col xs="12" sm="12" md="12" lg="6" xl="6">
          <InputPassword
            title="Senha"
            field="password"
            :formName="formName"
            :required="true"
            :maxLength="50"
            v-model="user.password"
          >
          </InputPassword>
        </b-col>
      </b-row>
      <b-row v-if="id && user.type == 2">
        <b-col sm="12">
          <RadioGroup
            title="Status"
            field="status"
            :formName="formName"
            :options="[
              { text: 'Ativo', value: 1 },
              { text: 'Inativo', value: 2 },
            ]"
            v-model="user.status"
          />
        </b-col>
      </b-row>
    </Molded>
    <br />
    <b-tabs v-if="id">
      <b-tab title="Regras">
        <UserRule :userType="user.type" />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded";
import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";
import InputWhatsApp from "@nixweb/nixloc-ui/src/component/forms/InputWhatsApp";
import InputPassword from "@nixweb/nixloc-ui/src/component/forms/InputPassword";
import RadioGroup from "@nixweb/nixloc-ui/src/component/forms/RadioGroup";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";

import UserRule from "./UserRule.vue";

import User from "@/components/modules/adm/user/User.js";

import { mapMutations, mapGetters, mapActions } from "vuex";

export default {
  name: "UserCreateUpdate",
  components: {
    Molded,
    InputText,
    InputWhatsApp,
    InputPassword,
    UserRule,
    RadioGroup,
    Button,
  },
  data() {
    return {
      id: this.$route.params.id,
      formName: "user",
      user: new User(),
      urlCreate: "/api/v1/adm/user/create",
      urlUpdate: "/api/v1/adm/user/update",
      urlGetById: "/api/v1/adm/user/get-by-id",
      urlConfirm: "/api/v1/adm/auth/sendconfirm-mail-mobile",
    };
  },
  created() {
    if (!this.id) this.removeLoading(["panel"]);
    if (this.id) this.getById();
  },
  methods: {
    ...mapMutations("validation", ["removeFormDirty"]),
    ...mapMutations("generic", ["removeLoading"]),
    ...mapActions("generic", ["postApi", "putApi", "getApi"]),
    create() {
      let params = { url: this.urlCreate, obj: this.user };
      this.postApi(params).then((response) => {
        if (response.success) {
          this.$router.push({
            name: "userUpdate",
            params: { id: response.content.id },
          });
        }
        this.removeLoading(["saveSaveCancel"]);
      });
    },
    update() {
      let params = { url: this.urlUpdate, obj: this.user };
      this.putApi(params).then((response) => {
        if (response.success) {
          this.getById();
        }
        this.removeLoading(["saveSaveCancel"]);
      });
    },
    getById() {
      let params = { url: this.urlGetById, obj: { userId: this.id } };
      this.getApi(params).then((response) => {
        if (response.success) {
          this.user.update(response.content);
          let self = this;
          setTimeout(function () {
            self.removeLoading(["panel"]);
            self.removeFormDirty();
          }, 100);
        }
      });
    },
  },
  computed: {
    ...mapGetters("generic", ["event"]),
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "saveSaveCancel") {
          if (!this.id) this.create();
          if (this.id) this.update();
        }
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.icon-success {
  color: green;
}

.icon-warning {
  color: #f6ba45;
}
</style>