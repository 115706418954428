<template>
  <div>
    <Loading v-show="loading" />
    <Rules v-if="!loading" :rules="rules" :userType="userType" :urlCreate="'/api/v1/adm/user-rule/create'"
      :urlDelete="`/api/v1/adm/user-rule/delete`"
      :urlCreateDeletePermission="'/api/v1/adm/user-rule/create-delete-permission'" />
    <Alert type="warning" v-if="!loading && rules.length == 0">
      <span> É necessário solicitar liberação dos módulos!</span>
    </Alert>
  </div>
</template>

<script>
import Rules from "./Rules.vue";
import Alert from "@nixweb/nixloc-ui/src/component/layout/Alert";
import Toggle from "@nixweb/nixloc-ui/src/component/forms/Toggle";
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading";

import { mapActions, mapState } from "vuex";

export default {
  name: "UserRule",
  components: { Alert, Toggle, Rules, Loading },
  props: {
    userType: Number,
  },
  data() {
    return {
      id: this.$route.params.id,
      urlGetRulesUser: "/api/v1/adm/user-rule/get-all",
      rules: [
        {
          name: "Adm",
          namePortuguese: "Adm",
          ruleId: "7561d235-1b31-400c-ab94-c4c06a8671ce",
          permission: [],
          hasRule: false,
        },
        {
          name: "remoteAccess",
          namePortuguese: "Acesso Remoto",
          ruleId: "fe018858-3049-4301-9cdb-429c1eef3c01",
          permission: [],
          hasRule: false,
        },
        {
          name: "Billing",
          namePortuguese: "Cobrança",
          ruleId: "07d883ab-4b09-447f-83f6-20f9c4c9d0dd",
          permission: [],
          hasRule: false,
        },
        {
          name: "CRM",
          namePortuguese: "CRM",
          ruleId: "e7bf2460-031d-4864-bde0-8f6b0f49c851",
          permission: [],
          hasRule: false,
        },
        {
          name: "Finance",
          namePortuguese: "Financeiro",
          ruleId: "6fd21094-ec1b-437b-8901-dbd0728edc9c",
          permission: [],
          hasRule: false,
        },
        {
          name: "Planner",
          namePortuguese: "Planner",
          ruleId: "1fbe7059-f623-4f52-99c3-f9e1604c2692",
          permission: [],
          hasRule: false,
        },
        {
          name: "Support",
          namePortuguese: "Suporte",
          ruleId: "fce1b49f-0166-4d46-888a-72b446ef922d",
          permission: [],
          hasRule: false,
        },
      ],
      loading: true,
    };
  },
  computed: {
    ...mapState("user", ["userLogged"]),
  },
  mounted() {
    this.getUserRules();
  },
  methods: {
    ...mapActions("generic", ["getApi"]),
    getUserRules() {
      let params = {
        url: this.urlGetRulesUser,
        obj: { userId: this.id },
      };
      this.getApi(params).then((response) => {
        let self = this;
        response.content.forEach(function (rule) {
          self.hasRule(rule);
        });
        this.loading = false;
      });
    },
    hasRule(userRule) {
      this.rules.forEach(function (rule) {
        if (rule.ruleId === userRule.ruleId) {
          rule.hasRule = true;
          rule.permission =
            userRule.permission == null ? [] : userRule.permission;
        }
      });
    },
  },
};
</script>
